<template>
  <Page color="grey" title="Kalender-Abos">
    <v-alert type="info" text>
      Die folgenden Kalender kannst du abonnieren!<br />
      <ul>
        <li>
          Klicke dazu auf den Eintrag, so dass der Link in die Zwischenablage
          kopiert wird.
        </li>
        <li>
          Nun kannst du in deiner Kalender-App den Link als «Web-Kalender-Abo»
          hinzufügen.
        </li>
        <li>
          Mit dem Abo wird der Kalender automatisch regelmässig aktualisiert
          (Intervall je nach App einstellbar)
        </li>
      </ul>
    </v-alert>
    <v-row>
      <v-col>
        <v-card class="mb-4" :loading="loading"
          ><v-system-bar>geschützte ICal-Abos</v-system-bar>

          <template v-if="icalSecret">
            <v-card-text>
              Die folgenden Kalender-Abos sind durch ein persönliches Geheimnis
              geschützt und beinhalten persönliche Informationen.
            </v-card-text>
            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="copyToClipboard(getIcalRoute('exam', true))">
                <v-list-item-icon
                  ><v-icon color="danger"
                    >mdi-octagram</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>meine Proben</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("exam", true) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="copyToClipboard(getIcalRoute('lesson', true))"
              >
                <v-list-item-icon
                  ><v-icon color="info"
                    >mdi-account-details</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>meine Lektionen</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("lesson", true) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="copyToClipboard(getIcalRoute('event', true))"
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, 'division=1'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine GH</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, "division=1") }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, 'division=2'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine MN</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, "division=2") }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$_hasRole('teacher')"
                @click="
                  copyToClipboard(getIcalRoute('event', true, 'division=3'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Termine WR</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("event", true, "division=3") }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-text>
              Hier findest du dein Geheimnis. Du kannst dieses löschen und neu
              generieren. Du musst dann aber die Links in den Kalender-Abos
              entsprechend anpassen.
            </v-card-text>

            <v-list subheader>
              <v-list-item @click="copyToClipboard(icalSecret)">
                <v-list-item-icon><v-icon>mdi-lock</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>Geheimnis</v-list-item-subtitle>
                  <pre>{{ icalSecret }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          <template v-else>
            <v-alert class="mb-0" tile text type="error">
              Erstelle zuerst ein «Geheimnis» über den «Generieren»-Knopf.
            </v-alert>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="danger"
              @click="removeIcalSecret"
              :disabled="!icalSecret"
              >Geheimnis löschen</v-btn
            ><v-btn
              text
              color="primary"
              @click="addIcalSecret"
              :disabled="!!icalSecret"
              >Geheimnis generieren</v-btn
            ></v-card-actions
          >
        </v-card>

        <v-card class="mb-4"
          ><v-system-bar>öffentliche ICal-Abos</v-system-bar>

          <template>
            <v-card-text>
              Die folgenden Kalender-Abos sind öffentlich verfügbar.
            </v-card-text>
            <v-divider></v-divider>
            <v-list>
              <v-list-item
                @click="copyToClipboard(getIcalRoute('holiday', false))"
              >
                <v-list-item-icon
                  ><v-icon color="warning"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle>Ferien</v-list-item-subtitle>
                  <pre>{{ getIcalRoute("holiday", false) }}</pre>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                @click="copyToClipboard(getIcalRoute('event', true))"
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false) }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, 'division=1'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich GH</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, "division=1") }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, 'division=2'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich MN</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, "division=2") }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="
                  copyToClipboard(getIcalRoute('event', false, 'division=3'))
                "
              >
                <v-list-item-icon
                  ><v-icon color="success"
                    >mdi-calendar</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Termine öffentlich WR</v-list-item-subtitle
                  >
                  <pre>{{ getIcalRoute("event", false, "division=3") }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </Page>
</template>
<script>
import { defineComponent } from "vue";
import { copyToClipboard } from "common/utils/helper.js";

export default defineComponent({
  components: {},
  name: "ICalendar",
  data() {
    return {
      count: 0,
      icalSecret: false,
      loading: false,
    };
  },
  watch: {},
  methods: {
    copyToClipboard,
    getIcalRoute(resource, addSecret, query) {
      if (addSecret) {
        return `${this.apiBase()}ical/${resource}/?account=${
          this.$_profilePerson.account
        }&secret=${this.icalSecret}${query ? "&" + query : ""}`;
      }
      return `${this.apiBase()}ical/${resource}/${query ? "?" + query : ""}`;
    },
    async getIcalSecret() {
      this.loading = true;
      const data = await this.apiGet({
        resource: "account/icalsecret",
        id: this.$_profilePerson.account,
      });
      this.icalSecret = data.icalSecret;
      this.loading = false;
    },
    async removeIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Das Geheimnis wird gelöscht. Bist du sicher? Du kannst dann ein neues generieren.`,
          color: "danger",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.account,
          key: "icalSecret",
          value: "delete",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
    async addIcalSecret() {
      if (
        await this.$root.confirm({
          message: `Ein Geheminis wird generiert und kann dann für den ICal-Zugriff verwendet werden.`,
          color: "success",
          icon: "mdi-lock",
        })
      ) {
        this.loading = true;
        await this.apiPatch({
          resource: "account/icalsecret",
          id: this.$_profilePerson.account,
          key: "icalSecret",
          value: "create",
        });
        await this.getIcalSecret();
        this.loading = false;
      }
    },
  },
  async created() {
    this.getIcalSecret();
  },
});
</script>
<style lang="css" scoped>
pre {
  font-size: small;
}
</style>
